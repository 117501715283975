:root {
  /* Light theme colors */
  --primary-color-light: #007bff;
  --background-color-light: #f8f9fa;
  --text-color-light: #212529;
  --message-background-light: #e2e3e5;

  /* Dark theme colors */
  --primary-color-dark: #0d6efd;
  --background-color-dark: #343a40;
  --text-color-dark: #f8f9fa;
  --message-background-dark: #e2e3e5;

  --gray: #adb5bd;
}

.light-theme {
  --primary-color: var(--primary-color-light);
  --background-color: #f8f9fa;
  --text-color: var(--text-color-light);
  --message-background: var(--message-background-light);
}

.dark-theme {
  --primary-color: var(--primary-color-dark);
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --message-background: var(--message-background-dark);
}

.chatApp {
  background-color: #f8f9fa;
  color: var(--text-color);
  min-height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
}

.toggleTheme{
  margin-left:auto;
}

@media (min-width: 768px) {
  .toggleTheme{
      display:none;
  }
}

.chatHeader{
  height:100px;
  border-bottom:1px solid var(--gray);
  font-size:16px;
  font-weight:bold;
  display: flex;
  align-items: center;
  padding-left:30px;
  padding-right:30px;
  box-sizing: border-box;
  gap:10px;
  position:fixed;
  z-index:100;
  background-color: #f8f9fa;
  width:70vw;
  right:0;
}

.chatContent{
  padding:20px;
  padding-top:120px;
  padding-bottom:80px;
  box-sizing: border-box;
  width:70vw;
  display:flex;
  flex-direction:column;
  min-height:100vh;
  justify-content: flex-end;
}

.chatContainer{
  display:flex;
  flex-direction:row;
  width:100%;
  box-sizing: border-box;
  background-color: #f8f9fa;
}




.panelContainer{
  width:30vw;
  background: #f8f9fa;
  border-right:1px solid var(--gray);
  box-sizing: border-box;
  position:relative;
}
body{
  margin:0px;
}

.panelContents{
  position:fixed;
  top:0;
  left:0;
  width:30vw;
  box-sizing: border-box;
  height:100vh;
  display: flex;
  flex-direction: column;
}

.modelListDiv{
  overflow-y:scroll;
  overflow-x:hidden;
}

.modelListDiv::-webkit-scrollbar { display: none; }



.message {
background-color: var(--message-background);
width: fit-content;
margin-bottom: 25px;  /* Reduced to accommodate timestamp */
padding-left: 20px;
padding-right: 20px;
border-radius: 25px;
max-width: 70%;
overflow-wrap: break-word;
word-wrap: break-word;
}

.message-container {
display: flex;
flex-direction: column;
margin-bottom: 8px;  /* Spacing between message groups */
}

.aipromo{
display:none;
}

.message-timestamp {
font-size: 11px;
margin-top: 2px;
position:absolute;
right:10px;
text-wrap: nowrap;
bottom:-15px;
color:rgba(0,0,0,0.5);
}

.message-container[data-user="true"] {
align-self: flex-end;
}

.chatContent > div:last-child {
margin-bottom: 200px;
}

.message-container[data-user="true"] .message-timestamp {
text-align: right;
}

.imageContainer{
  height:300px;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:-20px;
  margin-bottom:-20px;
}

.ChatImageSmall{
-webkit-touch-callout: none;
user-select: none;
}

.inputArea{
  display:flex;
  gap:20px;
  width:70vw;
  background-color:white;
  padding:20px;
  box-sizing:border-box;
  border-radius:0px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  position:fixed;
  bottom:0;
  right:0;
  z-index:15;
}

.submitlogo{
  opacity:0.7;
  margin-right:10px;
  cursor:pointer;
  transform: 0.2s;
}

.submitlogo:hover{
  opacity:1;
}

.inputField{
  width:100%;
  background:white;
  border:none;
  outline:none;
}


.loader {
  width: 24px;
  height: 24px;
  border: 5px solid;
  border-color: #ba00a8 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.modelProfile{
  align-self: center;
  margin-bottom:auto;
  padding-bottom:20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:10px;
  cursor:pointer;
  user-select: none;
}

.modelName{
  font-size:20px;
  font-weight:bold;
  opacity:0.9;
}

.followerCount{
  opacity:0.7;
}

.login{
  width:100%;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:black;
  font-weight:600;
  cursor:pointer;
}

audio{
  max-width: 80vw;
}

.modal{
  color:white;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
  grid-gap: 10px; /* Adjust the gap between grid items */
  max-width: 50vh; /* Adjust based on your requirement */
  margin: auto; /* Center the grid */
}

.photo-item {
  width: 100%;
  aspect-ratio: 1/1; /* This makes it square */
  object-fit: cover; /* This is already there */
  object-position: top; /* This ensures we show the top part of the image */
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}


.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top:20px;
  padding-bottom:20px;
  box-sizing: border-box;

}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: rgb(175, 175, 175);
  display: inline-block;
  height: 5px;
  margin: 3px;
  width: 5px;
  border-radius:2px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% { background-color: grey; transform: scale(1); }
  50% { background-color: #ffffff; transform: scale(1.3); }
  100% { background-color: grey; transform: scale(1); }
}

.bottomRef{
  position:relative;
  bottom:-50px;
}


@media (max-width: 768px) {
  .panelContainer{
      display:none;
  }
  .chatContent{
      width:100vw;
  }
  .inputArea{
      width:100vw;
  }
  .message{
      max-width:70vw;
  }
  .chatHeader{
      width:100vw;
  }
}

@media screen and (min-width: 768px) {
  .message{
      font-size:14px;
  }
  
}

.hovernoselect{
  user-select: none;
  cursor:pointer;
}

.menuChatCard{
  width: 90%;
  display: flex;
  flex-direction: row;
  padding:20px 30px 20px 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap:20px;
  cursor:pointer;
  transition:0.3s;
  position:relative;

}

.logout{
  width:100%;
  text-align:center;
  cursor:pointer;

}

.drawerBottom{
  margin-top:auto;
  margin-bottom:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:20px;
  font-weight:600;
  font-size:14px;
  box-sizing: border-box;
}

.drawerBottomDesktop{
  margin-top:auto;
  margin-bottom:30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
  gap:20px;
  font-weight:600;
  font-size:14px;
  color:rgba(0, 0, 0, 0.341);
  width:100%;
  padding-left:30px;
  padding-right:30px;
  box-sizing: border-box;
  padding-top:20px;
}

.drawerBottomDesktopButton{
  font-weight:600;
  font-size:13px;
  cursor:pointer;
  color:black;
}

.menuChatCard:hover{
  margin-left:20px;
}

.panelTitle{
  font-size:20px;
  font-weight:bold;
  opacity:0.8;
  padding:30px;
  width:100%;
  box-sizing: border-box;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between
}

.brandChat{
  color:black;
  font-weight:900;
  font-size:24px;
  letter-spacing: -1px;
  padding:30px 30px 0px 30px;
  width:100%;
}

.creditAmount{
  font-size:14px;
  font-weight:500;
  margin-right:30px;
  cursor:pointer;

}

.creditAmount:hover span{
  display:none;
}

.creditAmount:hover:before{
  content:"Get more!";
  color: #ff00d0;
  font-weight:600;
  transition:0.2s;
  cursor:pointer;
}

.menuChatCardName{
  font-size:14px;
  font-weight:500;
  opacity:0.8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:5px;
}

.menuChatCardDescription{
  font-size:12px;
  opacity:0.7;
}


.drawer{
  width:100vw;
}

.buyCredits{
  text-decoration:none;
  color:white;
  padding:10px;
  border-radius:20px;
  transition:0.2s;
  background:rgb(255, 0, 247);
  font-weight:500;
  cursor:pointer;
  font-size:14px;
  opacity:0.7;
}

.buyCredits:hover{
  opacity:1;
}


.scrollToBottom{
  position:fixed;
  bottom:80px;
  right:auto;
  z-index:200;
  background:#007bffd2;
  border-radius: 100px;
  padding:5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;
  gap:10px;
  color:white;
  font-weight:500;
  cursor:pointer;
}

.improveimage{
  position:absolute;
  top:20px;
  right:15px;
  color:white;
  font-weight:500;
  font-size:12px;
  background: #ff00d0;
  padding:3px 6px;
  border-radius:3px;
  cursor:pointer;
  z-index:10;
}

.getNotified{
  cursor:pointer;
  font-size:14px;
  color:white;
  background:#ba00a792;
  padding: 5px 10px;
  border-radius:5px;
  transition:0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right{
  margin-left:auto;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  gap:20px;
}

.getNotified-mobile{
  cursor:pointer;
  font-size:14px;
  color:white;
  background:#ba00a792;
  padding: 5px 10px;
  border-radius:5px;
  transition:0.2s;
  text-align:center;
  margin-top:10px;
}

.instagramFollow{
  cursor:pointer;
  font-size:14px;
  color:white;
  background:#ba00a792;
  padding: 5px 10px;
  border-radius:5px;
  transition:0.2s;
  text-align:center;
  margin-top:10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  text-decoration: none;
}

.encryptedsecure{
  cursor:pointer;
  font-size:13px;
  color:#ba00a792;
  padding: 5px 10px;
  border:1px solid #ba00a792;
  border-radius:5px;
  transition:0.2s;
  opacity:0.9;
  text-align:center;
  margin-top:10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  text-decoration: none;
}


.instagramFollow:hover{
  background:#ba00a7;
}

.getNotified:hover{
  background:#ba00a7;
}

@media (max-width: 768px) {
  .header-right{
      display:none;
  }
}


.oneclicksignup{
  text-align: center;
  font-size:18px;
  color:white;
  font-weight:600;
  text-shadow: 
  2px 2px 5px #0000005b, 
  -2px -2px 5px #0000005b, 
  2px -2px 5px #0000005b, 
  -2px 2px 5px #0000005b;
}

.accepttos{
  text-align: center;
  font-size:14px;
  color:white;
  font-weight:400;
  margin-top:20px;
  margin-bottom:20px;
  text-shadow: 
  2px 2px 5px #0000005b, 
  -2px -2px 5px #0000005b, 
  2px -2px 5px #0000005b, 
  -2px 2px 5px #0000005b;
}

.googleLogin{
  text-decoration:none;
  color:white;
  padding:10px 20px;
  border-radius:5px;
  transition:0.2s;
  background:rgba(255, 0, 247, 0.64);
  font-weight:500;
  cursor:pointer;
  font-size:14px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
}

.googleLogin:hover{
  background:#ff00f7;
}

.tosmodalcontent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upscaledimage{
  background-color: rgba(0, 0, 0, 0.822);
  display: flex;
  align-items: center;
  justify-content: center;
  height:80%;
  width:250px;
  border-radius:20px;
  color:white;
  font-weight:600;
  font-size:14px;
  text-align:center;
  cursor:pointer;
  transition:0.2s;
}

.upscaledimage:hover{
  background-color: rgba(0, 0, 0, 0.9);
}


.feedbackInput{
  border:none;
  outline:none;
  border-radius:10px;
  padding:10px;
  max-width:50vw;
  max-height:50vh;
  min-width:50vw;
}

@media screen and (max-width: 768px) {
  .feedbackInput{
      max-width:90vw;
      min-width:90vw;
  }
}

.feedbackTitle{
  font-size:20px;
  font-weight:bold;
  margin-bottom:10px;
}

.feedbackButton{
  padding:20px;
  width:100%;
  margin-top:20px;
  background:rgba(255, 0, 189, 0.7);
  border-radius:10px;
  color:white;
  border:none;
  font-weight:500;
  cursor: pointer;
  transition:0.2s;
}

.feedbackButton:hover{
  background:rgba(255, 0, 189, 0.9);
}

.sponsorWrapper2{
  width:100%;
  text-align:center;
  margin-top:40px;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  gap:20px;
}


.imageLoader{
  width:250px;
  height:250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  background-color: #dedede;
  border-radius: 20px;
  color:#ba00a7cd
}



.lds-heart,
.lds-heart div,
.lds-heart div:after,
.lds-heart div:before {
box-sizing: border-box;
}
.lds-heart {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
transform: rotate(45deg);
transform-origin: 40px 40px;
}
.lds-heart div {
top: 28px;
left: 28px;
position: absolute;
width: 32px;
height: 32px;
background: rgb(227, 158, 225);
animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
content: " ";
position: absolute;
display: block;
width: 32px;
height: 32px;
background: rgb(227, 158, 225);
}
.lds-heart div:before {
left: -24px;
border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
top: -24px;
border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
0% {
  transform: scale(0.95);
}
5% {
  transform: scale(1.1);
}
39% {
  transform: scale(0.85);
}
45% {
  transform: scale(1);
}
60% {
  transform: scale(0.95);
}
100% {
  transform: scale(0.9);
}
}

.allContactsMobile{
  color:#ba00a7;
  border:1px solid #ba00a7;
  padding:5px;
  border-radius:5px 10px;
  margin-left:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:3px;
  font-size:13px;

}


@media (min-width: 768px) {
  .allContactsMobile{
      display:none;
  }
  .loginmobile{
      display:none;
  }
}

@media (max-width: 768px) {
  .logindesktop{
      display:none;
  }
}

.signUpForCallsButton{
  padding:10px;
  background:#ba00a7;
  color:white;
  border-radius:10px;
  font-weight:600;
  cursor:pointer;
  transition:0.2s;
  text-align: center;
  margin-top:20px;
}

.signUpForCallsButton:hover{
  background:#ff00d0;
}







/* Complete Drawer Styles */
.drawer-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.drawer-contacts {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
/* Base drawer menu styles */
.drawer-menu {
  background: white;
  padding: 12px;
  border-top: 1px solid rgba(0,0,0,0.1);
  transition: height 0.3s ease;
}

.drawer-menu.expanded {
  height: auto;
}

.drawer-menu-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  margin-bottom: 6px;
}

.drawer-menu-expanded-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  margin-bottom: 6px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.drawer-menu-expanded-grid.show {
  max-height: 500px;
  opacity: 1;
  margin-bottom: 6px;
}

.drawer-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(135deg, #fdf2f8 0%, #f5f3ff 100%);
  transition: all 0.2s ease;
  text-decoration: none;
  position: relative;
}

.drawer-menu-item:hover {
  background: linear-gradient(135deg, #fce7f3 0%, #ede9fe 100%);
}


.yourowngirlmobilebutton{
  background:linear-gradient(135deg, #e40081 0%, #a8005a 100%);
  grid-column: span 2;
  border: 2px solid #db277873;
  opacity: 0.9;
  transition: all 0.2s ease;
  color:white;
}

.yourowngirlmobilebutton:hover {
  opacity: 1;
}

.makeownbeta{
  position:absolute;
  top:-4px;
  right:-4px;
  font-size:13px;
  font-weight:500;
  padding:3px 5px;
  border-radius:5px;
  background:white;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
  color:#a8005a;
  border: 1px solid #a8005a;
}

.drawer-menu-more {
  grid-column: span 2;
  border: 1px solid #db277873;
  opacity: 0.9;
  transition: all 0.2s ease;
}

.drawer-menu-more:hover {
  opacity: 1;
}

.drawer-menu-label {
  font-size: 11px;
  font-weight: 600;
  color: #6b7280;
  margin-bottom: 2px;
}

.drawer-menu-value {
  font-size: 15px;
  font-weight: 700;
  color: #db2777;
}


.drawer-section-title {
  padding: 24px 16px 16px;
  font-size: 18px;
  font-weight: 700;
  color: #374151;
}

/* Drawer animation and basic setup */
.MuiDrawer-root {
  transition: transform 0.3s ease-in-out;
}

.MuiDrawer-paper {
  width: 80vw;
  box-sizing: border-box;
}

/* ScrollBar Styling */
.drawer-contacts::-webkit-scrollbar {
  width: 6px;
}

.drawer-contacts::-webkit-scrollbar-track {
  background: transparent;
}

.drawer-contacts::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.drawer-contacts::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .drawer-menu {
    padding: 10px;
  }
  
  .drawer-menu-grid {
    gap: 5px;
  }
  
  .drawer-menu-item {
    padding: 6px;
  }
  
  .drawer-menu-label {
    font-size: 10px;
  }
  
  .drawer-menu-value {
    font-size: 14px;
  }
}




/* Side Navigation Styles */
.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #f8f9fa;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 100;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  opacity:0.7;
  transition:0.2s;
}

  .side-nav:hover{
      opacity:1;
  }

.side-nav-content {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.side-nav-logo {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
  padding: 0 4px;
}

.logo-circle {
  min-width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-text {
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
}

.logo-text.expanded {
  opacity: 1;
  transform: translateX(0);
}

.side-nav-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.side-nav-item {
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: #4b5563;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.side-nav-item:hover {
  background: #fdf2f8;
  color: #F339CA;
}

.side-nav-icon {
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.side-nav-item:hover .side-nav-icon {
  transform: scale(1.1);
}

.side-nav-item-content {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
  white-space: nowrap;
}

.side-nav-item-content.expanded {
  opacity: 1;
  transform: translateX(0);
}

.item-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}

.item-subtitle {
  font-size: 12px;
  opacity: 0.7;
}

.logout-item {
  margin-top: auto;
  margin-bottom: 16px;
  border: 1px solid #f3f4f6;
}

/* Adjust existing layout */
.panelContents {
  left: 72px;
  width: calc(30vw - 72px);
}

/* Hide on mobile */
@media (max-width: 768px) {
  .side-nav {
    display: none;
  }
  
  .panelContents {
    left: 0;
    width: 30vw;
  }
}


.side-nav-divider {
  height: 1px;
  background: #e5e7eb;
  margin: 8px 4px;
  opacity: 0.8;
}