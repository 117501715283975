/* Purple Landing Page Styles */
.planding-container {
    min-height: 100vh;
    background-color: #03060f;
    padding: 0 20px;
    padding-top:80px;
  }
  
  /* Hero Section */
  .planding-hero {
    padding: 60px 0 40px;
    text-align: center;
  }

  .planding-findyourgirl{
    padding-top:10px;
    text-align: center;
  }
  
  .planding-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    background: linear-gradient(to right, #c084fc, #e879f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .planding-highlight{
    background: linear-gradient(to right, #c084fc, #e879f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .planding-subtitle {
    font-size: 20px;
    color: #d1d5db;
    margin-bottom: 32px;
    margin-top:50px;
  }
  
  /* Grid Layout */
/* Grid Layout */
.planding-grid {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);  /* Changed from 2 to 1 for mobile */
    gap: 24px;
    padding-bottom: 80px;
  }
  
  @media (min-width: 640px) {
    .planding-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .planding-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* Set max-width for cards to maintain portrait aspect */
  .planding-card {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
  }
  
  /* Card Styles */
  .planding-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .planding-card:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 20px 25px -5px rgba(124, 58, 237, 0.1),
                0 10px 10px -5px rgba(124, 58, 237, 0.04);
  }
  
  /* Card Images */
  .planding-card-image-container {
    position: relative;
    height: 480px;
    background-color: #1f2937;
  }
  
  .planding-card-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  /* Overlay Gradient */
/* Overlay Gradient */
.planding-card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background: linear-gradient(
      to top,
      rgba(17, 24, 39, 1) 0%,
      rgba(17, 24, 39, 0.8) 30%,
      rgba(17, 24, 39, 0.2) 60%,
      rgba(17, 24, 39, 0) 100%
    );
    z-index: 1;
  }
  
  /* Card Content */
  .planding-card-content {
    position: absolute;
    inset: 0;
    z-index: 2;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .planding-card-top {
    display: flex;
    justify-content: flex-end;
  }
  
  .planding-chat-button {
    background-color: rgba(124, 58, 237, 0.9);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .planding-chat-textbutton{
    background-color: rgba(124, 58, 237, 0.9);
    border: none;
    border-radius: 10px;
    color:white;
    font-weight:600;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .planding-chat-button:hover {
    background-color: #7c3aed;
    transform: scale(1.1);
  }
  
  .planding-chat-button svg {
    width: 20px;
    height: 20px;
    color: white;
  }
  
  /* Card Info */
  .planding-card-info {
    color: white;
  }
  
  .planding-card-primary {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }
  
  .planding-card-name {
    font-size: 24px;
    font-weight: 600;
  }
  
  .planding-card-age {
    font-size: 20px;
  }
  
  .planding-card-location {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #d1d5db;
    font-size: 14px;
    margin-bottom: 12px;
  }
  
  .planding-location-icon {
    width: 14px;
    height: 14px;
  }
  
  /* Interest Tags */
  .planding-card-interests {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .planding-interest-tag {
    background-color: rgba(124, 58, 237, 0.3);
    color: #e879f9;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 12px;
    border: 1px solid rgba(124, 58, 237, 0.5);
    transition: all 0.2s ease;
  }
  
  .planding-card:hover .planding-interest-tag {
    background-color: rgba(124, 58, 237, 0.4);
    border-color: rgba(124, 58, 237, 0.7);
  }

  /* Description Block */
.planding-description-block {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease;
    margin-top: 0;
    margin-bottom:10px;
  }
  
  .planding-card:hover .planding-description-block {
    max-height: 100px;
    opacity: 1;
    margin-top: 8px;
  }
  
  .planding-card-description {
    color: #d1d5db;
    font-size: 14px;
    line-height: 1.4;
    padding-top: 4px;
  }

  /* Carousel Styles */
.planding-carousel {
    position: relative;
    height: 320px;
    max-width: 1280px;
    border-radius: 15px;
    margin-left:auto;
    margin-right:auto;
    overflow: hidden;
    background-color: #03060f;
  }

  @media screen and (max-width: 640px) {
    .planding-carousel {
      height: 240px;
    }
    
  }
  
  .planding-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
  }
  
  .planding-slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .planding-slide-overlay {
    position: absolute;
    inset: 0;
    /*background: linear-gradient(
      to bottom,
      rgba(3, 6, 15, 0.7),
      rgba(3, 6, 15, 0.5)
    );
    */
  }
  
  .planding-slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }
  
  .planding-nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(124, 58, 237, 0.7);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: white;
  }
  
  .planding-nav-button:hover {
    background-color: rgba(124, 58, 237, 0.9);
  }
  
  .planding-nav-prev {
    left: 20px;
  }
  
  .planding-nav-next {
    right: 20px;
  }
  
  .planding-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
  }
  
  .planding-dot {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .planding-dot-active {
    background-color: white;
    transform: scale(1.25);
  }
  
  /* Keep your existing fade animations */
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }

  /* Add these styles to your existing CSS file */

.planding-tabs {
    max-width: 1280px;
    margin: 0 auto 24px;
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 0 20px;
  }
  
  .planding-tab {
    padding: 12px 24px;
    border: 1px solid rgba(124, 58, 237, 0.5);
    background-color: rgba(124, 58, 237, 0.1);
    color: #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 500;
  }
  
  .planding-tab:hover {
    background-color: rgba(124, 58, 237, 0.2);
    border-color: rgba(124, 58, 237, 0.7);
  }
  
  .planding-tab-active {
    background-color: rgba(124, 58, 237, 0.3);
    border-color: rgba(124, 58, 237, 1);
    color: #e879f9;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .planding-tabs {
      gap: 8px;
    }
    
    .planding-tab {
      padding: 8px 16px;
      font-size: 14px;
    }
  }


  /* Navbar Styles */
.planding-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(3, 6, 15, 0.95);
    backdrop-filter: blur(8px);
    z-index: 1000;
    transition: transform 0.3s ease;
    border-bottom: 1px solid rgba(124, 58, 237, 0.2);
  }
  
  .navbar-visible {
    transform: translateY(0);
  }
  
  .navbar-hidden {
    transform: translateY(-100%);
  }
  
  .planding-navbar-content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .planding-navbar-logo {
    color: #e879f9;
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(to right, #c084fc, #e879f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .planding-navbar-tabs {
    display: flex;
    gap: 1rem;
  }
  
  /* Update the tab styles to fit in navbar */
  .planding-navbar .planding-tab {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(124, 58, 237, 0.5);
    background-color: rgba(124, 58, 237, 0.1);
    color: #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .planding-navbar .planding-tab:hover {
    background-color: rgba(124, 58, 237, 0.2);
    border-color: rgba(124, 58, 237, 0.7);
  }
  
  .planding-navbar .planding-tab-active {
    background-color: rgba(124, 58, 237, 0.3);
    border-color: rgba(124, 58, 237, 1);
    color: #e879f9;
  }
  
  .planding-navbar-actions {
    display: flex;
    align-items: center;
  }
  
  .planding-login-btn {
    padding: 0.5rem 1.5rem;
    background: linear-gradient(to right, #c084fc, #e879f9);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.3s ease;
    font-weight: 600;
  }
  
  .planding-login-btn:hover {
    opacity: 0.9;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .planding-navbar-content {
      padding: 1rem;
      flex-wrap: wrap;
      gap: 1rem;
    }
  
    .planding-navbar-tabs {
      order: 3;
      width: 100%;
      justify-content: center;
    }
  
    .planding-navbar .planding-tab {
      padding: 0.4rem 0.8rem;
      font-size: 0.8rem;
    }
  }