.paymentmodal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    z-index: 1000;
  }
  
  .paymentmodal-container {
    background: white;
    border-radius: 16px;
    max-width: 1000px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 20px 40px -8px rgba(0, 0, 0, 0.25);
    color:#1a202c;
  }

  .paymentmodal-closebutton-mobile{
    display: none;
  }

  @media screen and (max-width: 768px){
    .paymentmodal-container{
      height: 100vh;
      max-height:100%;
      width:100vw;
      overflow-x:hidden;
      border-radius:0px;
    }
    .paymentmodal-backdrop{
        padding:0;
    }
    .paymentmodal-closebutton{
        display:none;
    }
    .paymentmodal-closebutton-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        position:absolute;
        top: 5px;
        right:5px;
        padding:3px;
        background:white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius:50%;
        z-index:2000;
    }
    
  }

  .paymentmodal-closebutton{
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .paymentmodal-closebutton:hover{
    cursor: pointer;
  }
  
  .paymentmodal-content {
    padding-left: 24px;
    padding-right:24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .paymentmodal-highlight{
    color:#ff5070;
    font-weight: 600;
  }
  
  .paymentmodal-header {
    text-align: center;
    margin-bottom: 24px;
    background:url('./paymentbg.png');
    background-size: cover;
    background-position: center;
    min-height:150px;
    border-radius:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .paymentmodal-title {
    font-size: 32px;
    font-weight: 800;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
  }
  
  .paymentmodal-subtitle {
    color: white;
    font-size: 16px;
    max-width: 600px;
    line-height: 1.4;
  }

  @media screen and (max-width: 768px){
    .paymentmodal-subtitle{
        font-size: 14px;
        max-width: 80vw;
    }
  }
  
  .paymentmodal-timer {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    padding-bottom:10px;
  }
  
  .paymentmodal-time-block {
    background: linear-gradient(to bottom, #fafafa, #f3f3f3);
    padding: 12px;
    border-radius: 12px;
    text-align: center;
    min-width: 70px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(243, 76, 207, 0.1);
  }
  
  .paymentmodal-time-value {
    font-size: 24px;
    font-weight: 700;
    color: #1a202c;
    font-variant-numeric: tabular-nums;
  }
  
  .paymentmodal-time-label {
    font-size: 11px;
    color: #718096;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 2px;
    font-weight: 600;
  }
  
  .paymentmodal-separator {
    font-size: 24px;
    font-weight: 300;
    color: #a0aec0;
    padding-top: 12px;
  }
  
  .paymentmodal-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin: 24px 0;
  }
  
  .paymentmodal-left-column {
    display: flex;
    flex-direction: column;
  }
  
  .paymentmodal-right-column {
    display: flex;
    flex-direction: column;
  }
  
  .paymentmodal-plans {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .paymentmodal-plan {
    background: white;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    padding: 16px;
    position: relative;
    transition: all 0.2s ease;
    cursor: pointer;
    overflow: hidden;
  }
  
  .paymentmodal-plan::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #F34CCF;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .paymentmodal-plan:hover {
    transform: translateY(-2px);
    border-color: #F34CCF;
    box-shadow: 0 8px 16px -4px rgba(243, 76, 207, 0.15);
  }
  
  .paymentmodal-plan:hover::before {
    opacity: 1;
  }
  
  .paymentmodal-plan.selected {
    border-color: #F34CCF;
    background: linear-gradient(to bottom, rgba(243, 76, 207, 0.05), white);
    transform: translateY(-2px);
    box-shadow: 0 8px 16px -4px rgba(243, 76, 207, 0.2);
  }
  
  .paymentmodal-plan.selected::before {
    opacity: 1;
  }
  
  .paymentmodal-discount-tag {
    position: absolute;
    top: 20px;
    right: -28px;
    background: #F34CCF;
    color: white;
    padding: 4px 32px;
    transform: rotate(45deg);
    font-weight: 600;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(243, 76, 207, 0.2);
  }

  .paymentmodal-plan-tokens{
    color: #F34CCF;
    font-size: 12px;
    font-weight: 600;
    margin-bottom:3px;
  }
  
  .paymentmodal-plan-title {
    font-size: 18px;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: -3px;
  }
  
  .paymentmodal-plan-description {
    color: #4a5568;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 12px;
  }
  
  .paymentmodal-price-info {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-top: 12px;
  }
  
  .paymentmodal-original-price {
    color: #a0aec0;
    text-decoration: line-through;
    font-size: 14px;
  }
  
  .paymentmodal-current-price {
    font-size: 28px;
    font-weight: 800;
    color: #1a202c;
    letter-spacing: -0.5px;
  }
  
  .paymentmodal-current-price::before {
    font-size: 18px;
    font-weight: 600;
    vertical-align: top;
    margin-right: 2px;
  }
  
  .paymentmodal-best-value-badge {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #F34CCF;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(243, 76, 207, 0.3);
  }
  
  .paymentmodal-info {
    background: #f8fafc;
    border-radius: 12px;
    padding: 20px;
    height: 100%;
  }
  
  .paymentmodal-key-points {
    margin-bottom: 20px;
  }
  
  .paymentmodal-info-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: #4a5568;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .paymentmodal-info-item img {
    width: 16px;
    height: 16px;
    opacity: 0.9;
  }
  
  .paymentmodal-security {
    padding-top: 16px;
    border-top: 1px solid #e2e8f0;
  }
  
  .paymentmodal-footer {
    text-align: center;
    margin-top: 24px;
    width:100%;
  }
  
  .paymentmodal-cta-button {
    background: #F34CCF;
    color: white;
    border: none;
    padding: 14px 32px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    box-shadow: 0 4px 12px rgba(243, 76, 207, 0.2);
  }
  
  .paymentmodal-cta-button:not(:disabled):hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(243, 76, 207, 0.3);
    background: #f06dd8;
  }
  
  .paymentmodal-cta-button:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  .paymentmodal-secure-payments {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .paymentmodal-secure-text {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #4a5568;
    font-size: 13px;
    font-weight: 500;
  }
  
  
  .paymentmodal-payment-methods {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .paymentmodal-payment-methods img {
    height: 20px;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  
  .paymentmodal-payment-methods img:hover {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .paymentmodal-content {
      padding: 16px;
    }
  
    .paymentmodal-main {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  
    .paymentmodal-plans {
      order: 1;
    }
  
    .paymentmodal-footer {
      order: 2;
      margin-top: 16px;
      margin-bottom: 24px;
    }
  
    .paymentmodal-info {
      order: 3;
    }
  
    .paymentmodal-title {
      font-size: 24px;
    }
  
    .paymentmodal-plan {
      padding: 16px;
    }
  
    .paymentmodal-time-block {
      min-width: 60px;
      padding: 8px;
    }
  
    .paymentmodal-time-value {
      font-size: 20px;
    }
  
    .paymentmodal-payment-methods {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  .paymentmodal-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .paymentmodal-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  .paymentmodal-container::-webkit-scrollbar-thumb {
    background: rgba(243, 76, 207, 0.3);
    border-radius: 3px;
  }
  
  .paymentmodal-container::-webkit-scrollbar-thumb:hover {
    background: rgba(243, 76, 207, 0.5);
  }

  .paymentmodal-payment-section {
    margin-top: auto;
    padding-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .paymentmodal-payment-title {
    color: #718096;
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500;
  }
  
  .paymentmodal-payment-logos {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .payment-logo {
    height: 20px;
    width: auto;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  
  .payment-logo:hover {
    opacity: 1;
  }
  
  /* Make the info container flex for sticky bottom */
  .paymentmodal-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .paymentmodal-backdrop {
    animation: fadeIn 0.4s ease;
  }
  
  .paymentmodal-container {
    animation: slideIn 0.4s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { 
      opacity: 0;
      transform: translateY(20px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }
  