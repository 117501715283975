.customchat-container {
  display: flex;
  height: 100vh;
  background-color: #f9fafb;
  margin-left:60px;
}

@media (max-width: 768px) {
  .customchat-container {
    margin-left:0;
  }
}

.customchat-sidebar {
  width: 320px;
  background: white;
  border-right: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.customchat-user-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap:5px;
}

.customchat-user-info {
  font-size: 0.875rem;
  color: #374151;
}

.customchat-logout-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background: white;
  color: #D46BCB;
  border: 1px solid #D46BCB;
  font-size: 0.875rem;
  cursor: pointer;
}

.customchat-character-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customchat-list-header {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.customchat-new-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background: #D46BCB;
  color: white;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
}

.customchat-character-items {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  scrollbar-width: thin;
  scrollbar-color: rgb(212, 107, 203, 0.5) transparent;
}

.customchat-character-items::-webkit-scrollbar {
  width: 6px;
}

.customchat-character-items::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

.customchat-character-items::-webkit-scrollbar-thumb {
  background-color: rgb(212, 107, 203, 0.5);
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.customchat-character-items::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 0, 189, 0.8);
}

.customchat-character-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.customchat-character-item:hover {
  background: #f3f4f6;
}

.customchat-character-item.selected {
  background: rgba(255, 0, 189, 0.1);
}

.customchat-character-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  background: #D46BCB;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
}

.customchat-character-info {
  flex: 1;
  min-width: 0;
}

.customchat-character-info h3 {
  margin: 0;
  font-size: 0.875rem;
  color: #111827;
}

.customchat-nogirls{
  opacity:0.8;
  font-size:13px;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}

.customchat-character-info p {
  margin: 0;
  font-size: 0.75rem;
  color: #6b7280;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customchat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 0;
  min-height: 0; /* Add this */
  padding-bottom:60px;
  box-sizing: border-box;
}

.customchat-chat-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index:50;
}
.customchat-drawer-header{
  padding:10px;
}

.customchat-chat-title{
  display:flex;
  flex-direction: row;
  align-items: center;
  gap:10px;
}
.customchat-chat-title-desc{
  font-size: 0.75rem;
  color: #6b7280;
}

.customchat-messages {
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 0; /* Add this to ensure the container can scroll */
  padding-top:80px;
  margin-top: auto
}

.customchat-message-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.customchat-message-wrapper.user {
  align-self: flex-end;
}

.customchat-message-wrapper.ai {
  align-self: flex-start;
}

.customchat-text-message {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.customchat-message-wrapper.user .customchat-text-message {
  background: #D46BCB;
  color: white;
  border-bottom-right-radius: 0.25rem;
}

.customchat-message-wrapper.ai .customchat-text-message {
  background: #f3f4f6;
  color: #111827;
  border-bottom-left-radius: 0.25rem;
}

.customchat-message-time {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.25rem;
  align-self: flex-end;
}
.customchat-input-area {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  gap: 0.75rem;
  background: white;
  position:fixed;
  bottom:0;
  width:100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .customchat-input-area {
    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    gap: 0.75rem;
    background: white;
    position: fixed;
    bottom: 0;
    /* Add these properties: */
    right: 0;
    left: 380px; /* 320px sidebar + 60px margin-left */
    width: auto; /* Remove the 100% width */
  }
}


/* Add media query for mobile */

.customchat-input-area input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  background: #f9fafb;
  transition: all 0.2s;
}

.customchat-input-area input:focus {
  outline: none;
  border-color: #D46BCB;
  background: white;
}

.customchat-send-button {
  padding: 0.75rem 1.5rem;
  background: #D46BCB;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.customchat-send-button:hover {
  background: rgb(220, 0, 164);
}

.customchat-send-button:disabled {
  background: #e5e7eb;
  cursor: not-allowed;
}

.customchat-empty-state {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.customchat-empty-content {
  max-width: 400px;
}

.customchat-empty-content h2 {
  color: #111827;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.customchat-empty-content p {
  color: #6b7280;
  margin-bottom: 1.5rem;
}

.customchat-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.customchat-modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgb(212, 107, 203, 0.5) transparent;
}

.customchat-modal-content::-webkit-scrollbar {
  width: 6px;
}

.customchat-modal-content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

.customchat-modal-content::-webkit-scrollbar-thumb {
  background-color: rgb(212, 107, 203, 0.5);
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.customchat-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 0, 189, 0.8);
}

/* Custom scrollbar for textarea in modal */
.customchat-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  min-height: 100px;
  font-size: 14px;
  resize: vertical;
  scrollbar-width: thin;
  scrollbar-color: rgb(212, 107, 203, 0.5) transparent;
}

.customchat-textarea::-webkit-scrollbar {
  width: 6px;
}

.customchat-textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

.customchat-textarea::-webkit-scrollbar-thumb {
  background-color: rgb(212, 107, 203, 0.5);
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.customchat-textarea::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 0, 189, 0.8);
}

.customchat-modal-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customchat-modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #111827;
}

.customchat-modal-header button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6b7280;
  cursor: pointer;
  padding: 0.5rem;
}

.customchat-modal-body {
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customchat-modal-body input,
.customchat-modal-body textarea {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  background: #f9fafb;
  transition: all 0.2s;
}

.customchat-modal-body textarea {
  min-height: 100px;
  resize: vertical;
}

.customchat-modal-body input:focus,
.customchat-modal-body textarea:focus {
  outline: none;
  border-color: #D46BCB;
  background: white;
}

.customchat-modal-footer {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}

.customchat-modal-footer button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.customchat-modal-footer button:first-child {
  background: white;
  border: 1px solid #e5e7eb;
  color: #374151;
}

.customchat-modal-footer button:last-child {
  background: #D46BCB;
  border: none;
  color: white;
}

.customchat-info-grid {
  display: grid;
  gap: 1rem;
}

.customchat-info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.customchat-info-item label {
  font-size: 0.75rem;
  color: #6b7280;
  font-weight: 500;
  text-transform: uppercase;
}

.customchat-info-item span,
.customchat-info-item p {
  color: #111827;
  margin: 0;
  font-size: 0.875rem;
}

.customchat-image-message img {
  max-width: 300px;
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
}

.customchat-audio-message audio {
  max-width: 300px;
  border-radius: 0.5rem;
  background: #f3f4f6;
  padding: 0.5rem;
}

.customchat-typing-indicator {
  padding: 1rem;
  background: #f3f4f6;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.customchat-typing-indicator span {
  width: 0.5rem;
  height: 0.5rem;
  background: #9ca3af;
  border-radius: 50%;
  animation: typing 1.4s infinite ease-in-out both;
}

.customchat-typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.customchat-typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes typing {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

@media (max-width: 768px) {
  .customchat-container {
    flex-direction: column;
  }

  .customchat-sidebar {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
  }

  .customchat-main {
    height: 0;
    flex: 1;
  }

  .customchat-modal-content {
    width: 95%;
    max-height: 80vh;
  }

  .customchat-character-items {
    max-height: 300px;
  }
}

.customchat-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ff66c4, #8c1bab);
}

.customchat-login-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.customchat-login-card h1 {
  color: #111827;
  margin-bottom: 0.5rem;
}

.customchat-login-card p {
  color: #6b7280;
  margin-bottom: 2rem;
}

.customchat-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  background: #D46BCB;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.customchat-login-button:hover {
  background: rgb(220, 0, 164);
}

.customchat-login-button img {
  width: 1.5rem;
  height: 1.5rem;
}

/* Modal Styles */


.customchat-modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.customchat-modal-content::-webkit-scrollbar {
  width: 6px;
}

.customchat-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.customchat-modal-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
  transition: background 0.2s ease;
}

.customchat-modal-content::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.customchat-modal * {
  box-sizing: border-box;
}

.customchat-modal-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customchat-modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.customchat-modal-header button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.customchat-modal-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 100%;
}

.customchat-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.customchat-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  min-height: 100px;
  font-size: 14px;
  resize: vertical;
}

.customchat-textarea::-webkit-scrollbar {
  width: 6px;
}

.customchat-textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.customchat-textarea::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.customchat-textarea::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

/* Appearance Sections */
.customchat-appearance-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customchat-appearance-section {
  width: 100%;
}

.customchat-label {
  display: block;
  font-weight: 500;
  margin-bottom: 12px;
  color: #333;
}

/* Face Shape Selector */
.face-shape-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .face-shape-group {
    grid-template-columns: repeat(2, 1fr);
  }
  
}

.face-shape-option {
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.face-shape-option img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.face-shape-option span {
  font-size: 14px;
  color: #666;
}

.face-shape-option.active {
  border-color: #D46BCB;
  background-color: #e0e7ff;
}

.face-shape-option:hover {
  border-color: #D46BCB;
}

/* Toggle Groups */
.toggle-group {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.toggle-button {
  flex: 1;
  min-width: 100px;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #666;
}

.toggle-button:hover {
  border-color: #D46BCB;
}

.toggle-button.active {
  background: #e0e7ff;
  border-color: #D46BCB;
  color: #4f46e5;
}

.color-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Modal Footer */
.customchat-modal-footer {
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.customchat-button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.customchat-button.secondary {
  background: white;
  border: 1px solid #ddd;
  color: #666;
}

.customchat-button.secondary:hover {
  background: #f5f5f5;
}

.customchat-create-button {
  background: #D46BCB;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.customchat-create-button:hover {
  background: #4f46e5;
}

.customchat-create-button:disabled {
  background: #a5a6f6;
  cursor: not-allowed;
}

.customchat-character-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background: #D46BCB;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  overflow: hidden;
}

.customchat-character-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Input group for short bio with character counter */
.customchat-input-group {
  position: relative;
  width: 100%;
}

.customchat-char-counter {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #6b7280;
}

/* Personality traits section */
.customchat-personality-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.customchat-traits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  margin-bottom: 12px;
}

.customchat-trait-button {
  padding: 8px 12px;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  font-size: 12px;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s;
}

.customchat-trait-button:hover {
  background: #e5e7eb;
  border-color: #d1d5db;
}

/* Update input spacing for short bio */
.customchat-input-group input {
  padding-right: 60px; /* Make room for the character counter */
}


/* Progress dots container */
.customchat-progress-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
  border-bottom: 1px solid #e5e7eb;
}

.progress-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e5e7eb;
  transition: all 0.3s ease;
}

.progress-dot.active {
  background-color: #ba00a7;
  transform: scale(1.2);
}

/* Step content container */
.customchat-step-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

/* Generate button styling */
.customchat-generate-button {
  background-color: #f3f4f6;
  color: #374151;
  border: 1px solid #e5e7eb;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-top: 8px;
}

.customchat-generate-button:hover {
  background-color: #e5e7eb;
  border-color: #d1d5db;
}

/* Modal animation */
.customchat-modal {
  animation: fadeIn 0.2s ease;
}

.customchat-modal-content {
  animation: slideIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Step transitions */
.customchat-step-content {
  animation: fadeSlide 0.3s ease;
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Modal footer adjustments for multi-step */
.customchat-modal-footer {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

/* Make sure buttons take equal space */
.customchat-modal-footer button {
  flex: 1;
  max-width: 120px;
}


@media (max-width: 768px) {
  .customchat-sidebar {
    display: none;
  }
  
  .customchat-main {
    width: 100%;
  }

  .customchat-mobile-menu {
    display: block;
  }
  
  .customchat-menu-button {
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background: white;
    color: #374151;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

@media (min-width: 769px) {
  .customchat-mobile-menu {
    display: none;
  }
}
/* Base mobile menu styles */
.customchat-mobile-menu {
  display: none;  /* Hidden by default on desktop */
  margin-right: auto; /* Push other elements to the right */
}

.customchat-menu-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #D46BCB;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.customchat-menu-button:hover {
  border-color: #D46BCB;
}

/* Single media query for mobile styles */
@media (max-width: 768px) {
  .customchat-container {
    flex-direction: column;
  }

  .customchat-sidebar {
    display: none;
  }
  
  .customchat-main {
    width: 100%;
  }

  .customchat-mobile-menu {
    display: block;
  }

  .customchat-chat-header {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 1rem;
    position:fixed;
    top:0;
    width:100%;
    box-sizing: border-box;
  }
}

/* Single media query for desktop styles */
@media (min-width: 769px) {
  .customchat-mobile-menu {
    display: none;
  }
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .mobile-only {
    display: flex;
  }
}


.p-4{
  display:flex;
  flex-direction: column;
  gap:10px;
}

.gap-2{
  display:flex;
  gap:5px;
}


.customchat-image-loader {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f4f6;
  border-radius: 0.75rem;
}

.heart-loader {
  color: #D46BCB;
  font-size: 40px;
  animation: pulse 1.5s ease infinite;
  z-index:1;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.customchat-modal {
  background-color: rgba(0, 0, 0, 0.8); /* Darker backdrop for images */
}

.customchat-image-viewer {
  position: relative;
  display: inline-block;
}

.customchat-image-download {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #D46BCB;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #D46BCB;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customchat-image-download:hover {
  background: #f02cdf;
  transform: scale(1.1);
}

textarea {
  font-family: inherit;
}
/* Complete Drawer Styles */
.customchat-drawer-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.customchat-drawer-contacts {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.customchat-drawer-header {
  padding: 10px;
}

/* Base drawer menu styles */
.customchat-drawer-menu {
  background: white;
  padding: 12px;
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: auto;
  position: sticky;
  bottom: 0;
}

.customchat-drawer-menu.expanded {
  height: auto;
}

.customchat-drawer-menu-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* This is correct */
  gap: 6px;
  width: 100%;
}

.customchat-drawer-menu-expanded {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  margin: 6px 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.customchat-drawer-menu-expanded.show {
  max-height: 500px;
  opacity: 1;
}

.customchat-drawer-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(135deg, #fdf2f8 0%, #f5f3ff 100%);
  transition: all 0.2s ease;
  text-decoration: none;
  width: 100%;
  min-width: 0;  /* Add this to allow shrinking */
  box-sizing: border-box;
}

.customchat-drawer-menu-item:hover {
  background: linear-gradient(135deg, #fce7f3 0%, #ede9fe 100%);
}

.customchat-drawer-menu-item-full {
  grid-column: span 2;
  background: linear-gradient(135deg, #D46BCB 0%, #ba00a7 100%);
}

.customchat-drawer-menu-item-full:hover {
  background: linear-gradient(135deg, #D46BCB 0%, #ba00a7 100%);
  opacity: 0.9;
}

.customchat-drawer-menu-item-full .customchat-drawer-menu-label,
.customchat-drawer-menu-item-full .customchat-drawer-menu-value {
  color: white;
}

.customchat-drawer-menu-more {
  grid-column: span 2;
  border: 1px solid #db277873;
  opacity: 0.9;
  transition: all 0.2s ease;
}

.customchat-drawer-menu-create{
  background: linear-gradient(135deg, #D46BCB 0%, #ba00a7 100%);
  color: white;
}

.drawer-menu-more:hover {
  background: #fdf2f8;
}

.drawer-menu-more .customchat-drawer-menu-label,
.drawer-menu-more .customchat-drawer-menu-value {
  color: #db2777;
}

.customchat-drawer-menu-label {
  font-size: 11px;
  font-weight: 600;
  color: #6b7280;
  margin-bottom: 2px;
}

.customchat-drawer-menu-value {
  font-size: 15px;
  font-weight: 700;
  color: #D46BCB;
}

/* Make last item span full width if alone in row */
.customchat-drawer-menu-item:last-child:nth-child(odd) {
  grid-column: 1 / -1;
}

.customchat-drawer-menu-item.span-2 {
  grid-column: span 2;
}

/* ScrollBar Styling */
.customchat-drawer-contacts::-webkit-scrollbar {
  width: 6px;
}

.customchat-drawer-contacts::-webkit-scrollbar-track {
  background: transparent;
}

.customchat-drawer-contacts::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.customchat-drawer-contacts::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* Animation for expanded content */
.customchat-drawer-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .customchat-drawer-menu {
    padding: 10px;
  }
  
  .customchat-drawer-menu-grid {
    gap: 4px;
  }
  
  .customchat-drawer-menu-item {
    padding: 6px;
  }
  
  .customchat-drawer-menu-label {
    font-size: 10px;
  }
  
  .customchat-drawer-menu-value {
    font-size: 13px;
  }
}

/* Drawer animation and basic setup */
.MuiDrawer-root {
  transition: transform 0.3s ease-in-out;
}

.MuiDrawer-paper {
  width: 80vw;
  box-sizing: border-box;
}

/* Support for span-2 class */
.customchat-span-2 {
  grid-column: span 2;
}



.customchat-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #78006e, #2c0028);
  padding: 20px;
  box-sizing: border-box;
}

.customchat-login-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  background: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  flex-direction: column-reverse; /* Add this */
}

/* Then in your media query */
@media (min-width: 768px) {
  .customchat-login-container {
    flex-direction: row;
  }
}

.customchat-login-content {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 320px;
}

.customchat-login-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
  position:absolute;
  top:15px;
  left:15px;
  z-index:100;
  color:white;
}

.customchat-login-logo-text {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -1px;
}

.customchat-login-title {
  font-size: 40px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 16px;
  line-height: 1.2;
}

.customchat-login-subtitle {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 32px;
  line-height: 1.5;
}

.customchat-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: #D46BCB;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.customchat-login-button:hover {
  background: #ba00a7;
  transform: translateY(-2px);
}

.customchat-login-carousel {
  position: relative;
  overflow: hidden;
  display: block;
  height: auto; /* Fixed height on mobile */
  max-height:90vw;
  min-height:300px;
}

.customchat-carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.customchat-carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
}

@media (min-width: 768px) {
  .customchat-login-carousel {
    height: auto; /* Let it be flexible on desktop */
    flex: 1.5;
  }
}

@media (max-width: 768px) {
  .customchat-login {
    padding: 16px;
  }

  .customchat-login-container {
    max-width: 100%;
  }
  
  .customchat-login-content {
    padding: 24px;
    min-width: unset;
  }

  .customchat-login-header {
    margin-bottom: 24px;
  }

  .customchat-login-title {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .customchat-login-subtitle {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .customchat-login-button {
    padding: 14px;
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .customchat-login {
    padding: 12px;
  }

  .customchat-login-content {
    padding: 20px;
  }

  .customchat-login-title {
    font-size: 24px;
  }
}