@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background:#F8F9FA

}

.App {
  text-align: center;
  background-image:url("bg.png");
  background-repeat:no-repeat;
  background-size:cover;
  background-position: top right;
  width:100%;
  height:100vh;
}

@media screen and (max-width: 900px) {
  .App {
    background-position: -200px center; /* Start from 300px offset from the left */
  }
}

.query{
  width:100%;
  display: flex;
  flex-wrap: nowrap;
  height:70px;
  max-height:70px;
  box-sizing: border-box;
}

.rightSide{
  position:absolute;
  top:45%;
  right:10%;
  width:600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:5px;
}

.profile{
  border-radius: 25px; /* Adjusted for button alignment */
  border: 1px solid #ffffff28;
  background: rgba(255, 0, 189, 0.3);
  box-shadow: 0px 4px 50px 0px #000;
  backdrop-filter: blur(7.5px);
  padding:20px;
  width:100%;
  box-sizing: border-box;
  color:white;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  gap:5px;
}

.igbutton{
  padding:8px;
  background:rgba(255, 0, 189, 0.7);
  border-radius:10px;
  border:1px solid rgba(255, 255, 255, 0.5);
}

.searchbar{
  border-radius: 25px 0 0 25px; /* Adjusted for button alignment */
  border: 1px solid #ffffff28;
  background: rgba(255, 0, 189, 0.3);
  box-shadow: 0px 4px 50px 0px #000;
  backdrop-filter: blur(7.5px);
  padding:20px;
  color:white;
  font-weight:600;
  width:100%;
  height:70px;
  max-height:70px;
  box-sizing: border-box;
}

.searchbar:focus{
  outline:none;
}

.searchbar::placeholder {
  color:white;
  font-weight:600;
  opacity:0.6;
}

.button {
  border-radius: 0 25px 25px 0; /* Rounded on the right side to fit with the search bar */
  border: 1px solid #ffffff28; /* Same border as the search bar */
  background: rgba(255, 0, 189, 0.5); /* Same background as the search bar */
  box-shadow: 0px 4px 100px 0px #000; /* Same shadow as the search bar */
  color: white;
  backdrop-filter: blur(7.5px);

  font-weight: 600;
  padding: 20px;
  padding-left:40px;
  padding-right:40px;
  cursor: pointer; /* Cursor changes to pointer on hover */
  width:150px;
  height:70px;
  max-height:70px;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .rightSide {
    width:90vw;
    right:5vw;
    left:5vw;
  }
}

.button:hover {
  background: rgba(255, 0, 189, 0.67); /* Slightly darker on hover for visual feedback */
}

.nav{
  position:absolute;
  top:0;
  left:0;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  width:100%;
  padding:30px;
  box-sizing: border-box;
}

.brand{
  color:white;
  font-weight:900;
  font-size:24px;
  letter-spacing: -1px;
  text-shadow: 0px 4px 100px #000, 0px 4px 20px #000;
}

.link{
  color:white;
  font-weight:600;
  font-size:14px;
  text-shadow: 0px 4px 100px #000, 0px 4px 20px #000;
  cursor:pointer;
}

.links{
  display:flex;
  flex-direction:row;
  align-items: center;
  gap:25px;
  padding-left:10px;
  box-sizing: border-box;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.theporn{
  max-height:90vh;
  border-radius:20px;
}

.image-container {
  position: relative;
}

.download-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgba(255, 105, 180, 0.6); /* Pink, semi-transparent */
  color: white;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:0.1s ease-in-out;
}

.improve {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgba(255, 105, 180, 0.6); /* Pink, semi-transparent */
  color: white;
  border-radius: 5px;
  border:none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:0.1s ease-in-out;
}

.download-icon {
  /* Icon styling goes here, you can use a font icon or background image */
  background-image: url('download.png'); /* Path to your icon */
  width: 24px; /* Adjust size as needed */
  height: 24px;
  background-size: cover;
}

.download-button:hover{
  transform:scale(1.1);
  background-color: rgba(255, 105, 180, 1); /* Pink, semi-transparent */
}
.improve:hover{
  transform:scale(1.1);
  background-color: rgba(255, 105, 180, 1); /* Pink, semi-transparent */
}

.theporn {
  display: block;
  max-width: 100%;
  height: auto;
}


.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  padding: 20px;
  max-height: 90vh;
  overflow-y:scroll;
  width:100vw;
  box-sizing: border-box;
}

.gallery-image {
  width: 100%;
  min-width:300px;
  height: auto;
  object-fit: cover;
}

/* New styles for fullscreen */
.fullscreen-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything else */
}

.fullscreen-image {
  max-height: 90%;
  max-width: 90%;
  object-fit: contain;
}

.closeGallery{
  position:absolute;
  top:5px;
  right:20px;
  padding:10px;
  background-color: rgba(255, 105, 180, 0.8); /* Pink, semi-transparent */
  color: white;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:0.1s ease-in-out;
  z-index:10000;
}

.howto{
  background-color: rgb(255, 0, 128, 0.5); /* Pink, semi-transparent */
  color: white;
  border-radius: 10px;
  padding:5px 10px 5px 10px;
  font-size:13px;
  font-weight:600;
  z-index:5;
  transition:0.2s;
  cursor:pointer;
  border:none;
}

.howtogotit{
  background-color: rgb(255, 0, 128, 0.8); /* Pink, semi-transparent */
  color: white;
  border-radius: 10px;
  padding:20px;
  box-sizing: border-box;
  font-size:13px;
  font-weight:600;
  z-index:5;
  transition:0.2s;
  cursor:pointer;
  border:none;
  width:100%;
}

.howto:hover{
  background-color: rgb(255, 0, 128, 1); /* Pink, semi-transparent */

}

.howtomodal{
  width:50vw;
  height:auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color:white
}

@media screen and (max-width: 768px) {
  .howtomodal {
    width:90vw;
  }
  
}

.howtoexample{
  background-color: rgb(255, 0, 128, 0.5); /* Pink, semi-transparent */
  color:white;
  font-weight:600;
  padding:10px;
  text-align:left;
  border-radius:10px;
  font-size:13px;
}

.howtoexampletitle{
  font-weight:700;
  font-size:13px;
  text-align:left;
  margin-bottom:5px;
  margin-left:5px;
}

.howtoexampleexplainer{
  font-size:13px;
  text-align:left;
  margin-bottom:30px;
  margin-top:5px;
  margin-left:5px;
}

.assist{
  display: flex;
  gap:10px;
}

.backdrop2{
  z-index:10000;
}

.generating{
  font-weight:700;
  font-size:13px;
  text-align:center;
  margin-bottom:20px;
  color:white;
}


.image-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  background-image: url('titties.png'); /* Preset background image */
  background-size: cover;
  background-position: center;
  opacity: 0.9; /* Slightly opaque */
  width: 30%;
  height: 80%;
  border-radius: 20px;
  /* Additional styling as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size:24px;
  font-weight:600;
  color:white;
  padding:30px;
  box-sizing: border-box;
  text-shadow:rgba(0, 0, 0, 0.9) 1px 0 10px;
}

@media screen and (max-width: 900px) {
  .image-modal-content {
    width:90% /* Start from 300px offset from the left */
  }
}

.termsmodal{
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.acceptterms{
  margin-top:20px;
  border:1px solid white;
  padding:10px;
  border-radius: 10px;
  cursor: pointer;
  transition:0.2s;
  font-weight:600;
}

.acceptterms:hover{
  transform: scale(1.1);
}

.backdrop{
  background-color:rgba(0, 0, 0, 0.804);
}

.tos{
  padding:20px;
  box-sizing: border-box;
  color:rgb(54, 54, 54);
  background:#ffffff;
}

.tosCard{
  padding:20px;
  box-sizing: border-box;
  color:rgb(42, 42, 42);
  background:#f2f2f2;
  border-radius:10px;
  margin-bottom:20px;
  box-shadow: 0px 0px 10px 0px #0000002a;
}

.tosTitle{
  font-size:18px;
  font-weight:700;
  color:#4e4e4e
}

.credits{
  text-decoration:none;
  color:white;
  padding:20px;
  border:1px solid white;
  border-radius:10px;
  transition:0.2s;
}

.credits:hover{
  transform:scale(1.1);
}

.creditamount{
  font-size:12px;
}

.buyCredits{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.disclaimer{
  color:white;
  font-size:12px;
  margin-top:20px;
  width:300px;
}

.stripesecure{
  font-size:12px;
}

.randomizer{
  position:absolute;
  bottom:-20px;
  left:10px;
  font-size:12px;
  font-weight:600;
  color:white;
  cursor:pointer;
}

.swal2-popup {
  font-size: 14px !important;
}

@media screen and (min-width: 768px) {
  .App::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 150px;
    background: linear-gradient(transparent, rgb(10, 0, 23));
    z-index: 10; /* Adjust if necessary to ensure it's above other content */
  }
  
}


.about{
  color:white;
  padding:150px;
  box-sizing: border-box;
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:row;
  width:100%;
  gap:50px;
}

.about-left{
  width:50%;
}
.about-right{
  width:30vw;
}

@media screen and (max-width: 768px) {
  .about {
    padding:20px;
    flex-direction: column;
    align-items: center;
  }
  .about-right{
    width:90vw;
  }
  .about-left{
    width:90vw;
    text-align:center;
  }
}

.heading{
  font-size:48px;
}

.mainContent{
  opacity:0.8
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.image-container {
  position: relative;
  max-width: 90%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullimage {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 5px;
}

.fullscreen-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(transparent, black);
  z-index: 2;
}

.fullscreen-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  z-index: 3;
  font-size:13px;
}



.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a 3-column layout */
  grid-gap: 10px; /* Adjust the gap between grid items */
}

.grid-item {
  width: 100%; /* Full width of the column */
  padding-top: 100%; /* Padding-top of 100% creates a square aspect ratio */
  position: relative; /* Needed for absolute positioning of the image */
  cursor: pointer; /* Change cursor to indicate the image is clickable */
}

.gridimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the aspect ratio of the images */
  object-position: bottom; /* Align the top of the image with the top of the container */

  border-radius: 5px;
  transition:0.1s ease-in-out;
}

.gridimage:hover {
  opacity: 0.8; /* Slightly transparent on hover */
}


.highlight{
  color: rgba(255, 0, 189);
  font-weight:600;
}





/* Container for the cards */
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allows cards to wrap onto the next line on smaller screens */
  justify-content: space-around; /* Adjusts spacing between cards */
  margin: 0 auto; /* Center the container */
  max-width: 80vw; /* Maximum width of the container */
  color:white;
  box-sizing: border-box;
  margin-bottom:100px;
}

/* Individual card styling */
.card {
  flex: 0 0 30%; /* Each card takes up approximately 30% of the container width */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Example shadow for styling */
  margin: 10px; /* Margin around cards */
  padding: 20px; /* Padding inside cards */
  text-align: center; /* Center the text inside the card */
  box-sizing: border-box;
  border-radius: 20px;
  background: rgb(255,0,189);
  background: linear-gradient(103deg, rgba(255,0,189,0.7) 0%, rgba(192,32,249,0.7) 100%);
  box-shadow:rgb(89, 0, 65) 7px 7px 0px 0px;
    
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .card {
    flex: 0 0 100%; /* Each card takes full width on small screens */
  }
}


.statName{
  opacity:0.8
}

.statValue{
  font-size:50px;
  font-weight:700;
}

.yourGirls{
  font-size:13px;
  color:white;
  opacity:0.8;
  margin-bottom:5px;
}


.backdrop{
  z-index:1000;
}


.payment-options {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 30px; /* adjust as needed */
  max-width:100vw;
  max-height:90vh;

}





.payment-card {
  background: rgb(255, 0, 247, 0.6); /* example background */
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  flex-basis: calc(33.333% - 20px); /* for three cards */
  max-width: calc(33.333% - 20px); /* for three cards */
  text-align: center;
  color:white;
  position:relative;
}

.middle{
  background: rgb(255, 0, 247, 0.9); /* example background */
  border:2px solid white;
}

.payment-card h3 {
  margin-top: 0;
}

.payment-card p {
  font-size:14px;
  margin-bottom:50px;
}




.payButtonWrapper{
  margin-bottom:20px;
}

.payButton{
  text-decoration:none;
  color:white;
  padding:20px;
  border:3px solid white;
  border-radius:20px;
  transition:0.2s;
  background:rgb(255, 0, 247);
  font-weight:600;
  cursor:pointer;
}

.payButtonWrapper{
  transition:0.2s
}

.payButtonWrapper:hover{
  transform:scale(1.1);
}

.disclaimer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9em;
  width:100%;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .payment-card {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.modal3{
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
}

.stripePayments{
  color:white;
  margin-bottom:30px;
  font-weight:600;
  display: flex;
  align-items: center;
}

.strike{
  font-size:12px;
  text-decoration: line-through;
}

.tokenAmount{
  position:absolute;
  top:-15px;
  right:-15px;
  padding:10px;
  border: 3px solid white;
  z-index:100;
  background:linear-gradient(103deg, rgba(255,0,189,0.7) 0%, rgba(192,32,249,0.7) 100%);
  border-radius:5px;
  font-weight:600;
}

@media screen and (max-width: 768px) {
  .payment-options {
    flex-wrap: wrap;
    gap:20px;
  }
  .payment-card p {
    margin-bottom:30px;
  }
  .payButton{
    padding:10px 20px 10px 20px;
  }
  .payment-card h3{
    margin-bottom:0px;
  }
  .tokenAmount{
    padding:5px;
    font-size:12px;
  }
  .payment-card{
    padding:10px;
  }
}

.cancelButton{
  border-radius: 10px;/* Rounded on the right side to fit with the search bar */
  background: rgba(255, 0, 189, 0.5); /* Same background as the search bar */
  box-shadow: 0px 4px 100px 0px #000; /* Same shadow as the search bar */
  color: white;
  width:100px;
  padding:10px;
  margin-top:30px;
  cursor:pointer;
}

.loading-container{
  display:flex;
  align-items:center;
  justify-content: center;
  flex-direction:column;
}

.footer{
  height:150px;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  font-size:13px;
}

.footer-link{
  color:white;
  font-size:13px;
  opacity:0.7;
  margin:10px;
}


.btn-101,
.btn-101 *,
.btn-101 :after,
.btn-101 :before,
.btn-101:after,
.btn-101:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-101 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  font-size: 100%;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}
.btn-101:disabled {
  cursor: default;
}
.btn-101:-moz-focusring {
  outline: auto;
}
.btn-101 svg {
  vertical-align: middle;
}
.btn-101 [hidden] {
  display: none;
}
.btn-101 {
  --thickness: 0.3rem;
  --roundness: 1.2rem;
  --color: #eff6ff;
  --opacity: 0.6;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background: none;
  background: hsla(311, 100%, 50%, 0.425);
  border: none;
  border-radius: var(--roundness);
  color: var(--color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.6rem 2rem;
  position: relative;
  text-decoration: none;
}
.btn-101:hover {
  background: hsla(311, 100%, 50%, 0.674);
  filter: brightness(1.2);
}
.btn-101:active {
  --opacity: 0;
  background: hsla(0, 0%, 100%, 0.1);
}
.btn-101 svg {
  border-radius: var(--roundness);
  display: block;
  filter: url(#glow);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-101 rect {
  fill: none;
  stroke: var(--color);
  stroke-width: var(--thickness);
  rx: var(--roundness);
  stroke-linejoin: round;
  stroke-dasharray: 185%;
  stroke-dashoffset: 80;
  -webkit-animation: snake 2s linear infinite;
  animation: snake 2s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  width: 100%;
}
.btn-101:hover rect {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: var(--opacity);
}
@-webkit-keyframes snake {
  to {
    stroke-dashoffset: 370%;
  }
}
@keyframes snake {
  to {
    stroke-dashoffset: 370%;
  }
}


@media screen and (max-width: 768px) {
  .btn-101 {
    display: none;
  } 
}

@media screen and (min-width: 768px) {
  .trychatmobile{
    display:none
  }
}

.trychatmobile{
  position:absolute;
  right:10px;
  top:10px;
  padding:8px;
  background: hsla(311, 100%, 50%, 0.425);
  border-radius:10px;
  border:1px solid rgba(255, 255, 255, 0.5);
  color:white;
  text-decoration: none;
  padding:10px;
  font-weight:500;
  box-shadow: 0px 4px 20px 0px #00000061;
}


.pHunt{
  opacity:0.8;
  transition:0.2s;
  margin:0px;
  user-select: none;
}

.pHunt:hover{
  opacity:1
}

.pHunthref{
  padding:0px;
  height:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color:black;
}

.promoCard{
  width:250px;
  height:54px;
  border-radius: 10px;
  background:#FFFFFF;
  opacity:0.8;
  transition:0.2s;
  padding:10px;
  box-sizing: border-box;
  display: flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items:center;
  gap:10px;
  user-select: none;
}

.promoImage{
  border-radius:5px;
}

.promoCard:hover{
  opacity:1
}

.promoCards{
  width:100%;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
  gap:30px;
  flex-wrap:wrap;
  margin-top:30px;
}

.promoCardText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:0px;
}

.promoCardTitle{
  font-size:20px;
  font-weight:700;
  margin-top:-3px;
}

.promoCardSmall{
  font-size:10px;
  font-weight:500;
}

@media screen and (max-width: 768px) {
  .promoCards {
    display:none;
  }
  .btn-101-mobile{
    display:flex
  }
}

@media screen and (min-width: 768px) {
  .btn-101-mobile{
    display:none
  }
}

.sponsorWrapper{
  width:100%;
  text-align:center;
  margin-top:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:20px;
}

.sponsorCard{
  padding:10px 50px 10px 10px;
  border-radius:10px;
  box-sizing: border-box;
  background:#FFFFFF;
  color:black;
  text-decoration: none;
  opacity:0.9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap:10px;
  cursor:pointer;
  user-select: none;
  transition: 0.2s;
}

.sponsorCard:hover{
  opacity:1;
  transform:scale(1.02)
}

.sponsorCardTextWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:0px;

}

.sponsorCardName{
  font-size:20px;
  font-weight:700;
  margin-top:-3px;
}

.sponsorCardDescription{
  font-size:12px;
  font-weight:600;
}

.sponsorCardTitle{
  font-size:12px;
  opacity:0.8;
}