.mainApp{
    width:"100vw";
    height:"100vh";
    display:flex;
    flex-direction: row;
    color:rgba(0, 0, 0, 0.744)
}

.chaturbateLeft{
    background-image:url("https://static.vecteezy.com/system/resources/previews/005/482/783/non_2x/sweet-spiral-candy-background-swirl-abstract-caramel-pattern-twist-candy-background-vortex-lollipop-wallpaper-modern-design-illustration-vector.jpg");
    background-size:cover;
    background-position:center;
    width:50%;
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:30px;
    box-sizing: border-box;
}



.chaturbateRight{
    background:white;
    width:50%;
    height:100vh;
    padding:30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}

@media (max-width: 768px) {
    .mainApp{
        flex-direction: column;
    }
    .chaturbateLeft{
        width:100%;
        height:auto;
    }
    .chaturbateRight{
        width:100%;
        height:auto;
    }
    .chaturbateLogo{
        display:none;
    }
}

.chaturbateTitle{
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 700;
}

.chaturbateLogo{
    position: absolute;
    bottom:20px;
    right:20px;
}

.chaturbateStep{
    font-size: 18px;
    margin-top: 30px;
    font-weight: 700;
}

.chaturbateButton{
    text-decoration: none;
    background:#FF3B73;
    color:white;
    padding:10px 20px;
    cursor:pointer;
    border-radius:10px;
    font-weight:600;
}


.chaturbateLanding{
    width:100vw;
    height:100vh;
    background-image: url("chaturback.jpg");
    background-size:cover;
    background-position:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color:white;
    padding-left:20vw;
    padding-right:20px;
    box-sizing: border-box;
}

.chaturbateLandingTitle{
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}

.chaturbateLandingQuestion{
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}

.chaturbateLandingSlogan{
    font-size:18px;
    font-weight: 700;
    margin-top: 20px;
}

.chaturbateLandingButton{
    text-decoration: none;
    color:white;
    padding:10px 20px;
    cursor:pointer;
    font-weight:600;
    margin-top: 30px;
    border:2px solid white;
    transition:0.2s;
}

.chaturbateLandingButton:hover{
    background:white;
    color:black;
}