/* Partners Page Styles */
.partners-container {
    min-height: 100vh;
    background-color: #000;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
}

/* Hero Section */
.partners-hero-section {
    height: 40vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #000);
    margin-top: 80px;
}

.partners-hero-content {
    text-align: center;
    z-index: 1;
    padding: 0 20px;
}

.partners-heading {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 20px;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: 
        drop-shadow(0 0 10px rgba(254, 60, 114, 0.3))
        drop-shadow(0 0 20px rgba(254, 60, 114, 0.2))
        drop-shadow(0 0 30px rgba(254, 60, 114, 0.1));
}

.partners-subheading {
    font-size: 24px;
    color: white;
    opacity: 0.9;
}

/* Partners Grid Section */
.partners-grid-section {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
}

.partners-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.partner-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 20px;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    gap: 20px;
}

.partner-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(254, 60, 114, 0.2);
    border-color: rgba(254, 60, 114, 0.3);
}

.partner-image {
    height: 80px;
    width: auto;
    object-fit: contain;
    border-radius: 5px;
    flex-shrink: 0;
}

.partner-content {
    flex-grow: 1;
}

.partner-name {
    font-size: 20px;
    font-weight: bold;
    color: #fe3c72;
    margin: 0 0 5px 0;
}

.partner-title {
    font-size: 16px;
    color: white;
    opacity: 0.9;
    margin: 0 0 5px 0;
}

.partner-description {
    font-size: 14px;
    color: white;
    opacity: 0.7;
    margin: 0;
}

/* Footer */
.partners-footer {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 40px 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 80px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .partners-heading {
        font-size: 40px;
    }

    .partners-subheading {
        font-size: 20px;
    }

    .partners-grid-section {
        padding: 20px;
    }

    .partner-card {
        flex-direction: column;
        text-align: center;
    }

    .partner-image {
        height: 60px;
    }
}

/* Add these styles to your Partners.css file */

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #1a1a1a;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    color: white;
    border: 1px solid rgba(254, 60, 114, 0.3);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.05);
    color: white;
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }
  
  .modal-buttons button[type="submit"] {
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
  }
  
  .modal-buttons button[type="button"] {
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
  
  .add-partner-button {
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .add-partner-button:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
  
  .no-partners {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    padding: 40px;
    grid-column: 1 / -1;
  }