/* Base Styles */
.landing-container {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #000;
    font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-section {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform-style: preserve-3d;
}
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Adjust the opacity (0.5) to control darkness */
    z-index: 2;
}

.background {
    position: relative; /* Change to relative to properly contain the overlay */
}

.background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* If filter:brightness doesn't work well enough, use this overlay */
.background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9); /* Adjust opacity as needed */
    z-index: 1;
}

/* Keep your existing overlay for the gradient effect */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
}

/* Navigation */
/* Navigation */
/* Just update the nav styles */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.3s ease;

    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .nav {
        display: none;
    }
}

.nav-scrolled {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.nav-left {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-left: 50px;
}

.nav-links {
    display: flex;
    gap: 100px;
    font-size: 14px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.nav-links a:hover {
    color: #fe3c72;
}


@media (max-width: 768px) {
    .nav {
        padding: 15px 20px;
    }
    
    .nav-left {
        margin-left: 0;
    }
}

/* Main Content */
.main-content {
    text-align: center;
    padding: 0 20px;
    color: white;
    margin: auto 0;
    position: relative;
    z-index: 1;
}

.main-heading {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 20px;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: 
        drop-shadow(0 0 10px rgba(254, 60, 114, 0.3))
        drop-shadow(0 0 20px rgba(254, 60, 114, 0.2))
        drop-shadow(0 0 30px rgba(254, 60, 114, 0.1));
}

.sub-heading {
    font-size: 24px;
    margin-bottom: 32px;
}

.start-button {
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 999px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
}

.start-button-main{
    margin-top:70px;
    margin-bottom:-70px;

}

@media (max-width: 768px) {
    .start-button-main{
        margin-top:150px;
    }
}

.start-button:hover {
    opacity: 0.9;
    transform: scale(1.05);
}

/* Stats */
/* Stats */
.stats {
    display: flex;
    justify-content: center;
    padding: 40px;
    color: white;
    background: linear-gradient(to bottom, transparent, #000);
    backdrop-filter: blur(5px);
    position: relative;
    bottom: -3px;
    z-index: 1;
}

.stats-desktop {
    display: flex;
    gap: 80px;
}

.stats-mobile {
    display: none;
}

.stat {
    text-align: center;
}

.stat-number {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #fe3c72;
}

.stat-text {
    font-size: 14px;
    opacity: 0.9;
}

/* Animation classes */
.fade-out {
    opacity: 0;
    transform: translateY(-4px);
    transition: all 0.5s ease;
}

.fade-in {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease;
}

@media (max-width: 768px) {
    .stats-desktop {
        display: none;
    }
    
    .stats-mobile {
        display: block;
    }
    
    .stats {
        padding: 20px;
    }
}

/* Benefits Section */
.benefits-section {
    padding: 100px 40px;
    background: #000;
    position: relative;
    z-index: 1;
}

.section-title {
    text-align: center;
    font-size: 36px;
    color: white;
    margin-bottom: 80px;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.benefit-row {
    display: flex;
    align-items: center;
    gap: 60px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 0;
}

.benefit-row.reverse {
    flex-direction: row-reverse;
}

.benefit-image {
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.benefit-image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.benefit-row:hover .benefit-image img {
    transform: scale(1.05);
}

.benefit-content {
    flex: 1;
    color: white;
}

.benefit-content h3 {
    font-size: 32px;
    margin-bottom: 20px;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.benefit-content p {
    font-size: 18px;
    line-height: 1.6;
    opacity: 0.9;
}

/* Companions Section */
.companions-section-vanilla {
    padding: 100px 40px;
    background: black;
    opacity:1; /* Using rgb(0,0,0) for consistency */
    position: relative;
    z-index: 1;
}

.companions-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    background: black;  /* Add this */
    position: relative; /* Add this */
    z-index: 2;        /* Add this - should be higher than parent */
}

.companion-card {
    flex: 1;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.picCta{
    position:absolute;
    top:10px;
    right:10px;
    z-index:100;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
    border: 2px solid white;
    padding: 6px 20px;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.companion-card:hover {
    transform: translateY(-10px);
}

.companion-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
}

.companion-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.companion-name {
    line-height: 10px;
}

.companion-info h3 {
    font-size: 24px;
    margin-bottom: 5px;
    color: #fe3c72;
}

.companion-info p {
    font-size: 16px;
    opacity: 0.9;
}

/* Chat Component */
.chat-container-vanilla {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    height: 400px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .chat-container {
        width:90vw;
    }
}

.chat-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
}

.chat-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fe3c72;
}

.chat-name {
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.chat-indicator {
    color: #fe3c72;
    font-size: 12px;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px;
}

.chat-message {
    max-width: 80%;
    margin-bottom: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    animation: messageAppear 0.5s ease-out;
}

.chat-message.ai {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    margin-right: auto;
    border-bottom-left-radius: 4px;
}

.chat-message.user {
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
    margin-left: auto;
    border-bottom-right-radius: 4px;
}

@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Scrollbar styling */
.chat-messages::-webkit-scrollbar {
    width: 6px;

}

.chat-messages::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: rgba(254, 60, 114, 0.5);
    border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
    background: rgba(254, 60, 114, 0.8);
}
  
  /* Remove the old mobile styles for stats if they conflict */

/* Responsive Design */
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .main-heading {
        font-size: 40px;
    }

    .benefits-container,
    .companions-container {
        flex-direction: column;
        gap: 20px;
    }

    .benefit-row,
    .benefit-row.reverse {
        flex-direction: column;
        padding: 30px 0;
    }

    .benefit-card,
    .companion-card {
        width: 100%;
    }

    .section-title {
        font-size: 28px;
    }

    .benefit-content {
        text-align: center;
    }

    .benefit-content h3 {
        font-size: 28px;
    }

    .benefit-content p {
        font-size: 16px;
    }
}


.slideshow-container-vanilla {
    width: 500px;
    height: 500px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .slideshow-container-vanilla {
        width: 300px;
        height: 300px;
    }
    
}

.slideshow-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
}

@media (max-width: 768px) {
    .slideshow-container {
        width: 300px;
        height: 300px;
    }
}

.audio-wrap {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 20px;
    width: 500px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.audio-content {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.audio-avatar {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    position: relative;
}

.audio-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fe3c72;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
}

.play-button:hover {
    transform: translate(-50%, -50%) scale(1.1);
}

.audio-controls {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.progress-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.progress-slider {
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-top:15px;
    outline: none;
}

.progress-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background: #fe3c72;
    border-radius: 50%;
    cursor: pointer;
}

.time-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
}

@media (max-width: 768px) {
    .audio-wrap {
        width: 300px;
    }
}


/* Add these styles to your VanillaLanding.css */

.landing-container {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #000;
    font-family: Arial, sans-serif;
    /* Add these scrollbar styles specifically for landing-container */
    scrollbar-width: thin;
    scrollbar-color: rgba(254, 60, 114, 0.5) rgba(0, 0, 0, 0.1);
}

/* Scope webkit scrollbar styles to landing-container */
.landing-container::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

.landing-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.landing-container::-webkit-scrollbar-thumb {
    background: rgba(254, 60, 114, 0.5);
    border-radius: 4px;
    transition: background 0.3s ease;
}

.landing-container::-webkit-scrollbar-thumb:hover {
    background: rgba(254, 60, 114, 0.8);
}






.sticky-chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 20px;
    background: linear-gradient(to right, #fe3c72, #fd5564);
    color: white;
    border: 2px solid white;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .sticky-chat-button.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
  
  .sticky-chat-button:hover {
    background: linear-gradient(45deg, #ff8e8e, #ffb0b0);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }

  .landing-footer {
    background: rgb(0, 0, 0);
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .landing-footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    align-items: center;
  }
  
  .landing-footer-left {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    line-height: 1.5;
  }
  
  .landing-footer-description {
    margin-bottom: 16px;
    max-width: 600px;
    opacity: 0.8;
  }
  
  .landing-footer-copyright {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    opacity: 0.6;
  }
  
  .landing-footer-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: right;
    justify-content: center;
  }
  
  .landing-footer-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;
    opacity: 0.8;
  }
  
  .landing-footer-link:hover {
    color: #F339CA;
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .landing-footer-content {
      grid-template-columns: 1fr;
      text-align: center;
      gap: 1.5rem;
    }
  
    .landing-footer-description {
      margin: 0 auto 1rem auto;
    }
  
    .landing-footer-links {
      text-align: center;
    }
    
    .landing-footer {
      padding: 24px;
    }
  }
  


  .reviews-grid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .review-card {
    flex: 1;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 20px;
    backdrop-filter: blur(40px);
    transition: transform 0.2s ease;
    z-index:10;
    position:relative;
  }
  
  .review-card:hover {
    transform: translateY(-5px);
  }
  
  .review-content {
    position: relative;
    color: white;
  }
  
  .review-text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }
  
  .review-highlight {
    color: #cfcfcf;
    font-size: 14px;
    font-weight: 600;
    margin-top:5px;
    position:absolute;
    top:-40px;
    left:-5px;
  }
  
  @media (max-width: 768px) {
    .reviews-grid {
      flex-direction: column;
    }
    
    .review-card {
      width: 80vw;
      margin-bottom: 10px;
      padding:15px;
    }
  }


.hero-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    background: linear-gradient(to bottom, 
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    );
    transform-style: preserve-3d;
    transform: translateZ(0);
    z-index: 3;
    pointer-events: none;
}



.feature-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
  }
  
  .feature-label {
    color: white;
    padding: 6px 16px;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 500;
    transition: transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
  }
  
  .feature-label:hover {
    transform: translateY(-1px);
  }
  
  .label-divider {
    display: none;
  }
  
  @media (max-width: 768px) {
    .feature-label {
      font-size: 14px;
      padding: 5px 14px;
    }
  }


  
  .vanillalanding-reviews-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .vanillalanding-reviews-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 20px;
    backdrop-filter: blur(40px);
    transition: transform 0.2s ease;
    z-index: 10;
    position: relative;
  }
  
  .vanillalanding-reviews-content {
    position: relative;
    color: white;
    transition: all 0.5s ease;
  }
  
  .vanillalanding-content-fadeout {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .vanillalanding-content-fadein {
    opacity: 1;
    transform: translateY(0);
  }
  
  .vanillalanding-reviews-text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }
  
  .vanillalanding-reviews-highlight {
    color: #cfcfcf;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    position: absolute;
    top: -40px;
    left: -5px;
  }
  
  .vanillalanding-reviews-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 25px;
  }
  
  .vanillalanding-review-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .vanillalanding-review-dot-active {
    background: #fe3c72;
    transform: scale(1.3);
  }
  
  @media (max-width: 768px) {
    .vanillalanding-reviews-card {
      width: 80vw;
      margin-bottom: 10px;
      padding: 15px;
    }
  }