.Appcalling{
    background-image:url("anna1.png");
    background-size:cover;
    background-position:right;
    width:50vw;
    padding:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    overflow:hidden;
    border-radius: 30px;
  }
  
  .gradient-div {
    position: fixed;
    bottom: 0;
    height: 40vh;
    width: 100%; /* Adjust width as needed */
    background: linear-gradient(to top, black, transparent);
    z-index:0;
  }
  
  
/* App.css */
.annaimage {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  border: 10px solid white;
  z-index: 1;
  transition: border 0.3s ease-in-out;
}

.border-animation {
  animation: pulsate 0.7s infinite;
}

@keyframes pulsate {
  0% {
    border-color: white;
  }
  50% {
    border-color: rgb(163, 255, 159);
  }
  100% {
    border-color: white;
  }
}

  
  .calling{
    font-size:24px;
    color:white;
    margin-top:20px;
    z-index:1;
  
  }
  
  
  .annamaria{
    font-size:36px;
    font-weight:600;
    color:white;
    z-index:1;
  
  }
  
  .timer{
    font-size:20px;
    color:white;
    font-weight:600;
    margin-top:40px;
    z-index:1;
  }
  
  .speak{
    font-size:18px;
    color: white;
    font-weight:600;
    margin-top:20px;
    z-index:1;
    background: rgba(255, 255, 255, 0.484);
    border:none;
    padding:10px 20px;
    border-radius:20px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  
  .audioplayer{
    position:absolute;
    opacity:0;
  }