.makeyourown-container {
  background-color: #131313;
  min-height: 100vh;
  padding: 2rem;
  color: white;
  font-family: system-ui, -apple-system, sans-serif;
  display: flex;
  justify-content: center;
}

.makeyourown-wrapper {
  width: 100%;
  max-width: 1200px;
}

.makeyourown-card-wrapper {
  transition: transform 0.3s ease;
  height: 100%;
  display: block;
}

.makeyourown-progress {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 800px;
  margin: 0 auto 3rem;
}

@media screen and (max-width: 768px) {
  .makeyourown-progress {
    margin: 0 auto 0.5rem;

  }
  
}

.makeyourown-progress::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(255, 0, 208, 0.2);
  transform: translateY(-50%);
  z-index: 1;
}

.makeyourown-progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  background: linear-gradient(90deg, #ff00d0, #ff69b4);
  transform: translateY(-50%);
  transition: width 0.3s ease;
  z-index: 2;
}

.makeyourown-step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #131313;
  border: 2px solid rgba(255, 0, 208, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: relative;
  z-index: 3;
  transition: all 0.3s ease;
}

.makeyourown-step.active {
  border-color: #ff00d0;
  background: linear-gradient(135deg, #ff00d0, #ff69b4);
}

.makeyourown-step.completed {
  background: linear-gradient(135deg, #ff00d0, #ff69b4);
  border-color: #ff69b4;
}

.makeyourown-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.makeyourown-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.makeyourown-title {
  font-size: 2.5rem;
  margin-bottom: 0rem;
  font-weight: 700;
  background: linear-gradient(45deg, #ff00d0, #ff69b4, #ff1493);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top:0px;
}

.makeyourown-subtitle{
  margin-bottom:20px;
  font-size:16px;
}

.makeyourown-grid {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.makeyourown-grid-step1 {
  grid-template-columns: repeat(2, 1fr);
  max-width: 900px;
  margin: 0 auto;
  gap: 2rem;
}

@media (max-width: 768px) {
  .makeyourown-grid-step1 {
    grid-template-columns: 1fr;
  }
}

.makeyourown-grid-step2 {
  grid-template-columns: repeat(2, 1fr);
  max-width: 900px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .makeyourown-grid-step2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.makeyourown-card {
  background: transparent;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.makeyourown-grid-step1 .makeyourown-card-image {
  height: 400px;
}

.makeyourown-grid-step2 .makeyourown-card-image {
  height: 200px;
}

.makeyourown-card.selected::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 2px;
  background: linear-gradient(45deg, #ff00d0, #ff69b4, #ff1493);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.makeyourown-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  transition: none;
}

@media (max-width: 768px) {
  .makeyourown-card-image {
    max-height:70vw;
  }
}

.makeyourown-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  
}

.makeyourown-slideshow-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #131313;
}

.makeyourown-slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
}

.makeyourown-tag {
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: linear-gradient(135deg, rgba(255, 0, 208, 0.95), rgba(255, 105, 180, 0.95));
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  backdrop-filter: blur(4px);
  z-index: 2;
}

.makeyourown-form {
  width: 100%;
  max-width: 600px; /* Changed from 500px to 600px to match other sections */
  margin: 0 auto;
  box-sizing: border-box;
}

.makeyourown-input,
.makeyourown-textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 0, 208, 0.2);
  border-radius: 8px;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.makeyourown-input:focus,
.makeyourown-textarea:focus {
  outline: none;
  border-color: #ff00d0;
  background: rgba(255, 255, 255, 0.1);
}

.makeyourown-textarea {
  resize: vertical;
  min-height: 100px;
  max-height:150px;
  margin-bottom:0px;

}

.makeyourown-shesready{
  font-size:24px;
  font-weight:700;
  margin-bottom:15px;
}

.makeyourown-review {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
}

.makeyourown-review-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  text-align: left;
}

.makeyourown-review-item:last-child {
  margin-bottom: 0;
}

.makeyourown-review-label {
  font-weight: 600;
  color: #ff69b4;
  min-width: 120px;
  margin-right: 1rem;
}

.makeyourown-review-value {
  color: white;
  flex: 1;
}

.makeyourown-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 0 0rem;
}

.makeyourown-button {
  background: linear-gradient(135deg, #ff00d0, #ff69b4);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.makeyourown-button:hover {
  background: linear-gradient(135deg, #ff1dd7, #ff83c3);
  transform: translateY(-2px);
}

.makeyourown-button:disabled {
  background: linear-gradient(135deg, rgba(255, 0, 208, 0.3), rgba(255, 105, 180, 0.3));
  cursor: not-allowed;
  transform: none;
}

.makeyourown-button-secondary {
  background: rgba(255, 0, 208, 0.2);
}

.makeyourown-button-secondary:hover {
  background: rgba(255, 0, 208, 0.3);
}

.makeyourown-firsttag {
  width: 100%;
  height: 100%;
  background: #13131387;
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 48px;
  font-weight: 700;
  text-shadow: 2px 2px 10px #000000;
  padding: 15px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .makeyourown-firsttag {
    font-size: 32px;
  }
  
}

.makeyourown-features-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.makeyourown-feature-section {
  width: 100%;
}

.makeyourown-feature-title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ff69b4;
  text-align: center;
}

.makeyourown-feature-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.makeyourown-feature-button {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 0, 208, 0.2);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.makeyourown-feature-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: #ff00d0;
}

.makeyourown-feature-button.selected {
  background: linear-gradient(135deg, #ff00d0, #ff69b4);
  border-color: #ffffff;
}

.makeyourown-input-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.makeyourown-input-group .makeyourown-input {
  flex: 1;
  margin-bottom: 0;
}

.makeyourown-input[type="number"]::-webkit-inner-spin-button,
.makeyourown-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.makeyourown-input[type="number"] {
  -moz-appearance: textfield;
}

.makeyourown-autofill-button {
  width: 100%;
  background: linear-gradient(135deg, #ff00d0, #ff69b4);
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 15px rgba(255, 0, 208, 0.3);
}

.makeyourown-autofill-button:hover {
  background: linear-gradient(135deg, #ff1dd7, #ff83c3);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 0, 208, 0.4);
}

.makeyourown-autofill-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(255, 0, 208, 0.3);
}

@media (max-width: 768px) {
  .makeyourown-title {
    font-size: 2rem;
  }
  
  .makeyourown-step {
    width: 32px;
    height: 32px;
    font-size: 0.9rem;
  }
  
  .makeyourown-content {
    padding: 1rem;
  }
  
  .makeyourown-review {
    padding: 1.5rem;
  }
  
  .makeyourown-buttons {
    flex-direction: column;
  }
  
  .makeyourown-button {
    width: 100%;
  }
}

.makeyourown-language-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.makeyourown-language-card {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 0, 208, 0.2);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.makeyourown-language-card:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: #ff00d0;
  transform: translateY(-2px);
}

.makeyourown-language-card.selected {
  background: linear-gradient(135deg, rgba(255, 0, 208, 0.15), rgba(255, 105, 180, 0.15));
  border-color: #ff00d0;
  transform: translateY(-2px);
}

.makeyourown-language-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.makeyourown-language-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  margin: 0;
}

.makeyourown-language-description {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.makeyourown-audio-sample {
  margin-top: 0.5rem;
}

.makeyourown-audio-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 0, 208, 0.2);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.makeyourown-audio-button:hover {
  background: rgba(255, 0, 208, 0.3);
}

.makeyourown-audio-button.playing {
  background: #ff00d0;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 208, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 208, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 208, 0);
  }
}


  /* Loading Screen Styles */
  .makeyourown-loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    gap: 2rem;
  }
  
  .makeyourown-loading-spinner {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .makeyourown-spinner-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #ff00d0;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  
  .makeyourown-spinner-circle:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .makeyourown-spinner-circle:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .makeyourown-spinner-circle:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .makeyourown-loading-text {
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    background: linear-gradient(45deg, #ff00d0, #ff69b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: pulse 2s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  
  /* Final Card Styles */
  .makeyourown-final-card {
    width: 100%;
    max-width: 600px;
    height: 600px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .makeyourown-final-card {
      max-height: 400px;
    }
    
  }
  
  .makeyourown-final-image {
    width: 100%;
    height: 100%;
  }
  
  .makeyourown-final-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .makeyourown-final-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, 
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 50%,
      transparent 100%);
    padding: 2rem;
    color: white;
  }
  
  .makeyourown-final-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .makeyourown-final-name {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .makeyourown-final-bio {
    font-size: 1.2rem;
    opacity: 0.9;
    margin: 0.5rem 0;
  }
  
  .makeyourown-final-details {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }
  
  .makeyourown-final-tag {
    background: rgba(255, 0, 208, 0.3);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .makeyourown-final-personality {
    font-size: 1rem;
    opacity: 0.8;
    margin: 0.5rem 0;
    line-height: 1.4;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .makeyourown-final-card {
      height: 600px;
      border-radius: 15px;
    }
  
    .makeyourown-final-overlay {
      padding: 1.5rem;
    }
  
    .makeyourown-final-name {
      font-size: 2rem;
    }
  
    .makeyourown-final-bio {
      font-size: 1rem;
    }
  
    .makeyourown-loading-spinner {
      width: 60px;
      height: 60px;
    }
  
    .makeyourown-loading-text {
      font-size: 1rem;
    }
  }