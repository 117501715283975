.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
  
.signup-modal {
  background-color: #f8f9fa;
  border-radius: 15px;
  display: flex;
  width: 100%;
  max-width: 900px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.modal-image-section {
  display: none;
  width: 50%;
  background-color: #f0f0f0;
  position: relative; /* Added */
  min-height: 600px; /* Added */
}

.modal-image {
  position: absolute; /* Changed */
  top: 0; /* Added */
  left: 0; /* Added */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  .modal-content-section {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .close-button:hover {
    opacity: 1;
  }
  
  .modal-title {
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  
  .input-group:last-child {
    margin-bottom: 24px;
  }
  
  .modal-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    border: 1px solid #adb5bd;
    background-color: white;
    color: #000;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.2s;
  }
  
  .modal-input:focus {
    outline: none;
    border-color: #F339CA;
  }
  
  .modal-input::placeholder {
    color: #adb5bd;
  }
  
  .password-input-wrapper {
    position: relative;
  }
  
  .toggle-password {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #adb5bd;
    cursor: pointer;
    padding: 0;
  }
  
  .password-hint {
    color: #adb5bd;
    font-size: 0.875rem;
    margin: 4px 0 0 0;
  }
  
  .create-account-button {
    background-color: #F339CA;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s;
    font-size: 1rem;
    opacity: 0.9;
  }
  
  .create-account-button:hover {
    opacity: 1;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    color: #adb5bd;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    flex: 1;
    border-top: 1px solid #adb5bd;
  }
  
  .divider span {
    padding: 0 1rem;
    font-size: 0.875rem;
  }
  
  .social-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .social-buttons-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  
  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid #adb5bd;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    background-color: white;
    color: #000;
  }
  
  .social-button img {
    width: 20px;
    height: 20px;
  }
  
  .social-button:hover {
    border-color: #F339CA;
    background-color: #f8f9fa;
  }
  
  .social-button.facebook {
    background-color: white;
    color: #000;
  }
  
  .social-button.facebook:hover {
    border-color: #F339CA;
    background-color: #f8f9fa;
  }
  
  .social-button.google {
    background-color: white;
    color: #000;
  }
  
  .social-button.x {
    background-color: white;
    color: #000;
  }
  
  .social-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .login-link {
    text-align: center;
    color: #000;
    margin: 1.5rem 0;
    font-size: 0.9rem;
  }
  
  .login-link a {
    color: #F339CA;
    text-decoration: none;
    font-weight: 500;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }
  
  .terms {
    text-align: center;
    color: #7f93a7;
    font-size: 0.875rem;
    margin: 0;
  }
  
  .terms a {
    color: #99b6d3;
    text-decoration: none;
  }
  
  .terms a:hover {
    color: #000;
  }
  
  .error-message {
    background-color: #fdebed;
    color: #dc3545;
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    border: 1px solid #f5c2c7;
    width: 100%;
    box-sizing: border-box;
  }
  
  .forgot-password-link {
    background: none;
    border: none;
    padding: 0;
    font-size: 13px;
    color: #666;
    cursor: pointer;
    transition: color 0.2s;
    margin-top: 4px;
    text-align: right;
    width: 100%;
  }
  
  .forgot-password-link:hover {
    color: #F339CA;
    text-decoration: underline;
  }
  
  @media (min-width: 768px) {
    .modal-image-section {
      display: block;
    }
    
    .modal-content-section {
      width: 50%;
    }
    
    .signup-modal {
      min-height: 600px;
      margin: 2rem;
    }
  }
  
  @media (max-width: 767px) {
    .signup-modal {
      margin: 1rem;
      max-width: 400px;
    }
    
    .social-buttons-group {
      grid-template-columns: 1fr;
    }
  }

  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
  }

  /* Add these to your existing SignupModal.css file */

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}

.loading-spinner {
  animation: spin 1s linear infinite;
  color: #F339CA;
}

.loading-text {
  color: #666;
  font-size: 0.875rem;
  text-align: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loginloader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #ff9ae9 #ff7fe3 transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loginloader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #ff7fe3 #ff7fe3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    